import moment from "moment"

export const isFutureDate = dateInQuestion => {
  let { month, date, year } = dateInQuestion
  let dateToday = new Date()

  if (date)
    return dateToday < moment(`${month.value} ${date.value} ${year}`).toDate()
  return false
}
