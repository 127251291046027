import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import React, { useEffect } from "react"
import Img from "gatsby-image"

import Layout from "layout/Layout"
import SEO from "layout/SEO"
import Container from "layout/Container"
import { Contact } from "../Elements/Contact"

import {
  GATSBY_PATIENT_ENROLLMENT_KEY,
  GATSBY_PATIENT_ENROLLMENT_VALUE,
} from "gatsby-env-variables"

export default () => {
  const data = useStaticQuery(graphql`
    {
      complete: file(relativePath: { eq: "pages/complete__icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const complete = data.complete.childImageSharp.fluid

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_PATIENT_ENROLLMENT_KEY) !==
      GATSBY_PATIENT_ENROLLMENT_VALUE
    ) {
      navigate("/patient/")
    }
  }, [])

  return (
    <Layout>
      <SEO title="Submission Completed" />
      <Container isCentered>
        <center>
          <Container isCentered mobile={8} tablet={6} desktop={4}>
            <Img fluid={complete} alt="Success!" />
          </Container>
          <p className="pb-1">Thank you for enrolling in the HOPE Program.</p>
          <p className="pb-1">
            Your enrollment is currently being processed. You will receive an
            SMS confirming your eligibility within one working day. Once you are
            successfully enrolled, you may proceed with your financial
            assessment.
          </p>
          <p className="pb-3">
            Feel free to reach us at <Contact /> for any questions.
          </p>
          <Link to="/" className="button is-primary is-medium">
            Back to Home Page
          </Link>
        </center>
      </Container>
    </Layout>
  )
}
