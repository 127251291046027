import React from "react"
import classNames from "classnames"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import styles from "../utils/enrollment.module.scss"

const CommunicationSection = ({ enrollmentData }) => {
  return (
    <section>
      <Section
        title="Communication Preferences"
        isRequired={true}
        addOns={{
          right: <EditDetailsButton route="/patient/enroll" />,
        }}
      >
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              <tr>
                <td className="has-text-weight-bold">
                  I want to be contacted through{" "}
                </td>
                <td className={classNames(styles["summary__tableData"])}>
                  {enrollmentData.contactedThrough.join(", ")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Section>
    </section>
  )
}

export default CommunicationSection
