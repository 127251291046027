import { useStaticQuery, graphql } from "gatsby"

export default form => {
  const enrollmentFormData = useStaticQuery(graphql`
    {
      allGoogleEnrollmentformSheet {
        nodes {
          section
          subtitle
          isSectionRequired
          type
          name
          placeholder
          options
          optionsString
          pattern
          disabledOptionsString
          label
          isRequired
          helper
          maxLength
        }
      }
    }
  `)

  let enrollmentNodes = enrollmentFormData?.allGoogleEnrollmentformSheet?.nodes

  let tempFormSections = []
  let formSections = enrollmentNodes
    .map(data => {
      if (!tempFormSections.includes(data.section)) {
        tempFormSections.push(data.section)
        return {
          section: data.section,
          subtitle: data?.subtitle,
          isRequired: data.isSectionRequired,
          fields: [],
        }
      }
      return null
    })
    .filter(section => section != null)

  enrollmentNodes = enrollmentNodes.map(data => {
    if (data?.optionsString) {
      if (data?.type === "select" || data?.type === "diagnosis") {
        data.options = data?.optionsString?.split(", ").map(data => {
          return { value: data, label: data }
        })
      } else data.options = data?.optionsString?.split(", ")
    }
    if (data?.disabledOptionsString) {
      data.disabledOptions = data?.disabledOptionsString?.split(", ")
    }
    return data
  })

  for (let counter = 0; counter < formSections.length; counter++) {
    formSections[counter].fields = enrollmentNodes.filter(
      data => data.section === formSections[counter].section
    )
  }

  if (form === "PatientDetails") return formSections.slice(0, 2)

  return formSections.slice(2)
}
