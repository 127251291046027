import React, { useState, useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import Container from "layout/Container"

import SEO from "layout/SEO"
import Layout from "layout/Layout"
import Message from "elements/Message"
import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"
import EditDetailsButton from "elements/EditDetailsButton"
import PersonalInformationSection from "./EnrollmentSummary/PersonalInformationSection"
import PrescriptionDetailsSection from "./EnrollmentSummary/PrescriptionDetailsSection"
import CommunicationPreferenceSection from "./EnrollmentSummary/CommunicationPreferenceSection"
import PrescriptionInformationSection from "./EnrollmentSummary/PrescriptionInformationSection"

import { AppContext } from "../../context/AppContext"
import { RESET_STATE } from "../../context/AppReducer"
import { handleEnrollPatient } from "./services/patientEnrollment"

import {
  GATSBY_PATIENT_ENROLLMENT_KEY,
  GATSBY_PATIENT_ENROLLMENT_VALUE,
} from "gatsby-env-variables"

const PatientEnrollmentSummary = () => {
  const [hasSubmissionError, setHasSubmissionError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  let enrollmentData = state.patientEnrollment

  const handleSubmitError = ({ message }) => {
    setHasSubmissionError(true)
    setErrorMessage(message)
    setLoading(false)
  }

  const handleSubmit = values => {
    setLoading(true)
    setHasSubmissionError(false)
    handleEnrollPatient({
      enrollmentData: values,
      documents: state.documents,
      callback: () => {
        dispatch({
          type: RESET_STATE,
        })
        setLoading(false)
        navigate("/patient/enroll/complete")
      },
      errorCallback: handleSubmitError,
    })
  }

  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_PATIENT_ENROLLMENT_KEY) !==
      GATSBY_PATIENT_ENROLLMENT_VALUE
    ) {
      navigate("/patient/")
    }
  }, [])

  return (
    <Layout
      title="Patient Enrollment Summary"
      subtitle="Please review if the following information is correct."
    >
      <SEO title="Enrollment Summary" />
      <Formik
        // validationSchema={consentAndAuthValidation}
        initialValues={state.patientEnrollment}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Container isCentered desktop={6} fullhd={6}>
              <PersonalInformationSection enrollmentData={enrollmentData} />
              <CommunicationPreferenceSection enrollmentData={enrollmentData} />
              <PrescriptionInformationSection enrollmentData={enrollmentData} />
              <Section
                title="Prescription Uploaded"
                isRequired
                addOns={{
                  right: <EditDetailsButton route="/patient/enroll" />,
                }}
              >
                <PrescriptionDetailsSection />
              </Section>
              {/* <ConsentAndAuthorization values={values} /> */}
              {hasSubmissionError && (
                <Message color="danger">{errorMessage}</Message>
              )}
            </Container>
            <ActionButtons
              className="mb-2"
              submit={{ label: "Submit", loading }}
              back={{ label: "Back", link: "/patient/enroll/" }}
            />
          </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default PatientEnrollmentSummary
