import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import PatientEnrollmentPersonalDetails from "./PatientEnrollmentPersonalDetails"
import PatientEnrollmentPrescriptionDetails from "./PatientEnrollmentPrescriptionDetails"

import {
  GATSBY_PATIENT_ENROLLMENT_KEY,
  GATSBY_PATIENT_ENROLLMENT_VALUE,
} from "gatsby-env-variables"

const PERSONAL_DETAILS = "PERSONAL_DETAILS"
const PRESCRIPTION_DETAILS = "PRESCRIPTION_DETAILS"

const PatientEnrollment = () => {
  useEffect(() => {
    if (
      sessionStorage.getItem(GATSBY_PATIENT_ENROLLMENT_KEY) !==
      GATSBY_PATIENT_ENROLLMENT_VALUE
    ) {
      navigate("/patient/")
    }
  }, [])

  return (
    <Layout
      title="Patient Enrollment Form"
      subtitle="Please ensure that the information provided below is correct."
      seoTitle="Patient Enrollment"
    >
      <Container isCentered>
        <PatientEnrollmentView />
      </Container>
    </Layout>
  )
}

const PatientEnrollmentView = () => {
  const [view, setView] = useState(PERSONAL_DETAILS)

  switch (view) {
    case PERSONAL_DETAILS:
      return <PatientEnrollmentPersonalDetails setView={setView} />
    case PRESCRIPTION_DETAILS:
      return <PatientEnrollmentPrescriptionDetails setView={setView} />
    default:
      return null
  }
}

export default PatientEnrollment
