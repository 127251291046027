import React, { Fragment, useContext } from "react"
import { Formik, Form } from "formik"

import Message from "../Elements/Message"
import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"

import { AppContext } from "../../context/AppContext"
import { patientEnrollment } from "../../context/AppReducer"
import { generateFormField } from "../Elements/Form/services/form"
import getPatientEnrollmentForm from "./utils/getPatientEnrollmentForms"
import { patientEnrollmentValidation } from "./utils/patientEnrollmentValidations"

const PRESCRIPTION_DETAILS = "PRESCRIPTION_DETAILS"

const PatientEnrollmentPersonalDetails = ({ setView }) => {
  const { state, dispatch } = useContext(AppContext)
  const ENROLLMENT_FORM = getPatientEnrollmentForm("PatientDetails")

  const handlePersonalDetailsSubmit = values => {
    dispatch({
      type: patientEnrollment.SAVE_PATIENT,
      payload: { ...values },
    })
    setView(PRESCRIPTION_DETAILS)
  }

  return (
    <Fragment>
      <Formik
        validationSchema={patientEnrollmentValidation}
        initialValues={state.patientEnrollment}
        onSubmit={handlePersonalDetailsSubmit}
      >
        {({ values, setFieldValue, isValid, submitCount }) => (
          <Form>
            {ENROLLMENT_FORM.map(section => (
              <Section
                title={section?.section}
                subtitle={section?.subtitle}
                isRequired={section?.isRequired}
              >
                {section?.fields.map(field => {
                  if (!field?.referenceAnswer) {
                    return generateFormField({
                      formFields: section?.fields,
                      formField: field,
                      values,
                      setFieldValue,
                    })
                  }
                  return null
                })}
              </Section>
            ))}

            {!isValid && submitCount > 0 && (
              <Message color="danger">
                You may have missed some required fields. Please scan through
                the form and check if your information is complete.
              </Message>
            )}

            <ActionButtons
              back={{ label: "Back", link: "/" }}
              submit={{ label: "Next" }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default PatientEnrollmentPersonalDetails
