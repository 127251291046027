import { GATSBY_ENV } from "gatsby-env-variables"
import { capitalize } from "../../../services/general"
import { sendToZendesk } from "../../../services/zendeskService"

import { patientEnrollmentZendeskTemplate } from "../templates/patientEnrollmentZendeskTemplate"
// import { sendEmail } from "../../../services/sendEmail"
import { handleError } from "../../../services/handleError"

export const sendEnrollmentFormToZendesk = config => {
  let { enrollmentData, documents, ticketTemplate, type } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"

  let tags = [`${type}_enrollment`, "bm_hope"]
  if (isTest) tags.push("test")

  let subject = `${isTest ? "[TEST] " : ""}${capitalize(
    type
  )} Enrollment Form of ${enrollmentData?.firstName} ${
    enrollmentData?.lastName
  }`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.emailAddress,
    },
    comment: { body: ticketTemplate({ enrollmentData }) },
  }

  return sendToZendesk(requestBody || {}, documents || [])
}

export const handleEnrollPatient = async ({
  enrollmentData,
  documents,
  callback,
  errorCallback,
}) => {
  try {
    await sendEnrollmentFormToZendesk({
      enrollmentData,
      documents,
      ticketTemplate: patientEnrollmentZendeskTemplate,
      type: "Patient",
    })

    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}

// function formatName(firstName, lastName, middleInitial) {
//   const MI = middleInitial ? ` ${middleInitial} ` : " "
//   return `${firstName}${MI}${lastName}`
// }
