import React, { Fragment, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Message from "../Elements/Message"
import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import UploadGuidelines from "../Elements/UploadGuidelines"
import UploadPrescription from "../Elements/UploadPrescription"

import { AppContext } from "../../context/AppContext"
import { patientEnrollment } from "../../context/AppReducer"
import { generateFormField } from "../Elements/Form/services/form"
import getPatientEnrollmentForm from "./utils/getPatientEnrollmentForms"
import { prescriptionValidationSchema } from "./utils/patientEnrollmentValidations"
import { isFutureDate } from "./utils/date"

const PERSONAL_DETAILS = "PERSONAL_DETAILS"

const PatientEnrollmentPrescriptionDetails = ({ setView }) => {
  const { state, dispatch } = useContext(AppContext)
  const ENROLLMENT_FORM = getPatientEnrollmentForm()

  const handlePrescriptionSubmit = (values, { setErrors }) => {
    let { medicationStartDate, diagnosisDate, nextAppointmentDate } = values

    let dateErrors = {}
    if (isFutureDate(medicationStartDate)) {
      dateErrors.medicationStartDate = {
        month: { value: "Please enter a valid prescription date." },
        date: { value: "Please enter a valid prescription date." },
      }
    }

    if (isFutureDate(diagnosisDate)) {
      dateErrors.medicationStartDate = {
        month: { value: "Please enter a valid prescription date." },
        date: { value: "Please enter a valid prescription date." },
      }
    }

    if (!isFutureDate(nextAppointmentDate)) {
      dateErrors.nextAppointmentDate = {
        month: { value: "Please enter a valid next appointment date." },
        date: { value: "Please enter a valid next appointment date." },
      }
    }

    if (Object.keys(dateErrors).length > 0) {
      setErrors(dateErrors)
    } else {
      if (state.documents.length < 1) return values
      dispatch({
        type: patientEnrollment.SAVE_PATIENT,
        payload: { ...values },
      })
      navigate("/patient/enroll/summary")
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <Formik
        validationSchema={prescriptionValidationSchema}
        initialValues={state.patientEnrollment}
        onSubmit={handlePrescriptionSubmit}
      >
        {({ values, setFieldValue, isValid, submitCount }) => (
          <Form>
            {ENROLLMENT_FORM.map(section => (
              <Section
                title={section?.section}
                isRequired={section?.isRequired}
              >
                {section?.fields.map(field => {
                  if (!field?.referenceAnswer) {
                    return generateFormField({
                      formFields: section?.fields,
                      formField: field,
                      values,
                      setFieldValue,
                    })
                  }
                  return null
                })}
              </Section>
            ))}
            <Section title="Upload Prescription" isRequired={true}>
              <UploadGuidelines />
              <UploadPrescription />
              {submitCount > 0 && state.documents.length < 1 && (
                <p className="help mt-0 mb-1 is-danger">
                  Please upload your prescription to proceed
                </p>
              )}
            </Section>
            {!isValid && submitCount > 0 && (
              <Message color="danger">
                You may have missed some required fields. Please scan through
                the form and check if your information is complete.
              </Message>
            )}
            <ActionButtons
              back={{
                label: "Back",
                callback: () => {
                  setView(PERSONAL_DETAILS)
                },
              }}
              submit={{ label: "Next" }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default PatientEnrollmentPrescriptionDetails
