import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import styles from "../utils/enrollment.module.scss"

const CommunicationSection = ({ enrollmentData }) => {
  let { prescribingDoctor, otherDiagnosis } = enrollmentData

  let hospitalName = enrollmentData.hospitalClinicName.value
  if (hospitalName === "Other") hospitalName = enrollmentData.otherHospital

  let startDate = enrollmentData.medicationStartDate
  let startDateOfMedication = `${startDate?.month?.value} ${startDate?.date?.value}, ${startDate?.year}`

  let diagnosisDate = enrollmentData.diagnosisDate
  let dateOfDiagnosis = `${diagnosisDate?.month?.value} ${diagnosisDate?.date?.value}, ${diagnosisDate?.year}`
  let { month, date, year } = diagnosisDate

  let nextAppointmentDate = enrollmentData.nextAppointmentDate
  let nextAppointment = `${nextAppointmentDate?.month?.value} ${nextAppointmentDate?.date?.value}, ${nextAppointmentDate?.year}`

  let diagnosisReason =
    enrollmentData.diagnosis.value !== "Other (Please specify)"
      ? enrollmentData.diagnosis.value
      : enrollmentData.otherDiagnosis

  let checklist = [
    diagnosisReason,
    prescribingDoctor,
    startDate?.month?.value,
    nextAppointmentDate?.month?.value,
  ]

  let hasValues = true
  for (let condition in checklist) {
    if (!checklist[condition]) {
      hasValues = false
    }
  }

  return (
    <Section
      title="Prescription Information"
      isRequired={true}
      addOns={{
        right: <EditDetailsButton route="/patient/enroll" />,
      }}
    >
      {!hasValues && (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            No prescription information found.
          </p>
        </Message>
      )}
      {hasValues && (
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              <tr>
                <td className="has-text-weight-bold">Prescribing Doctor</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {enrollmentData.prescribingDoctor}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Clinic or Hospital</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {hospitalName}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Diagnosis</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {diagnosisReason !== "Other (please specify)"
                    ? diagnosisReason
                    : otherDiagnosis}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Medication start date:</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {startDateOfMedication}
                </td>
              </tr>
              {month.value && date.value && year && (
                <tr>
                  <td className="has-text-weight-bold">Diagnosis date</td>
                  <td className={classNames(styles["summary__tableData"])}>
                    {dateOfDiagnosis}
                  </td>
                </tr>
              )}
              <tr>
                <td className="has-text-weight-bold">
                  With confirmed HRCT imaging test
                </td>
                <td className={classNames(styles["summary__tableData"])}>
                  {enrollmentData.withConfirmedHRCT}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Next appointment date</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {nextAppointment}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}

export default CommunicationSection
