import React from "react"
import { Router } from "@reach/router"

import PatientEnroll from "../components/Enrollment/PatientEnroll"
import PatientEnrollment from "../components/Enrollment/PatientEnrollment"
import PatientEnrollmentSummary from "enrollment/PatientEnrollmentSummary"
import PatientEnrollmentComplete from "enrollment/PatientEnrollmentComplete"

export default () => {
  return (
    <Router basepath="/patient">
      <PatientEnroll path="/" />
      <PatientEnrollment path="/enroll" />
      <PatientEnrollmentSummary path="/enroll/summary" />
      <PatientEnrollmentComplete path="/enroll/complete" />
    </Router>
  )
}
