import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import styles from "../utils/enrollment.module.scss"

const PersonalInformationSection = ({ enrollmentData }) => {
  let { firstName, lastName, emailAddress, mobileNumber } = enrollmentData
  let { month, date, year } = enrollmentData.birthday
  let fullName = `${firstName} ${lastName}`
  let birthday = `${month.value} ${date.value}, ${year}`

  let checklist = [firstName, lastName, emailAddress, mobileNumber, month.value]

  let hasValues = true
  for (let condition in checklist) {
    if (!checklist[condition]) hasValues = false
  }

  return (
    <Section
      title="Personal Information"
      isRequired={true}
      addOns={{
        right: <EditDetailsButton route="/patient/enroll" />,
      }}
    >
      {!hasValues && (
        <Message color="warning">
          <p className="has-text-black is-size-6">
            No personal information found.
          </p>
        </Message>
      )}
      {hasValues && (
        <div class="table-container">
          <table class="table is-fullwidth is-size-5">
            <tbody className={classNames("body")}>
              <tr>
                <td className="has-text-weight-bold">Name</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {fullName}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Email</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {emailAddress}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Mobile Number</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {mobileNumber}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-bold">Birthday</td>
                <td className={classNames(styles["summary__tableData"])}>
                  {birthday}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}

export default PersonalInformationSection
