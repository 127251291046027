export const patientEnrollmentZendeskTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    landline,
    birthday,
    contactedThrough,
    hospitalClinicName,
    otherHospital,
    prescribingDoctor,
    prescribedMedicine,
    medicationStartDate,
    diagnosis,
    otherDiagnosis,
    diagnosisDate,
    withConfirmedHRCT,
    nextAppointmentDate,
  } = enrollmentData

  let hospitalName =
    hospitalClinicName.value === "Other"
      ? otherHospital
      : hospitalClinicName.value

  let birthdayInformation = `${birthday.month.value} ${birthday.date.value} ${birthday.year}`
  let medicationStart = `${medicationStartDate.month.value} ${medicationStartDate.date.value} ${medicationStartDate.year}`
  let diagnosisDateInfo = `${diagnosisDate.month.value} ${diagnosisDate.date.value} ${diagnosisDate.year}`
  let nextAppoinment = `${nextAppointmentDate.month.value} ${nextAppointmentDate.date.value} ${nextAppointmentDate.year}`

  let currentDiagnosis =
    diagnosis.value === "Other (please specify)"
      ? otherDiagnosis
      : diagnosis.value

  diagnosisDateInfo =
    diagnosisDate.month.value && diagnosisDate.date.value && diagnosisDate.year
      ? diagnosisDateInfo
      : "N/A"

  return `Personal Details
  First Name: ${firstName}
  Last Name: ${lastName}
  Middle Initial: ${middleInitial || "N/A"}
  Email: ${emailAddress}
  Mobile Number: ${mobileNumber}
  Landline: ${landline || "N/A"}
  Birthday: ${birthdayInformation}
  -----
  Communication Preferences
  Preferred means of contact: ${contactedThrough.join(", ")}
  -----
  Prescription Details
  Hospital or Clinic: ${hospitalName}
  Prescribing Doctor: ${prescribingDoctor}
  Prescribed Medicine: ${prescribedMedicine.value}
  Start date of medication: ${medicationStart}
  Diagnosis: ${currentDiagnosis}
  Diagnosis Date: ${diagnosisDateInfo}
  With confirmed HRCT: ${withConfirmedHRCT}
  Next appointment: ${nextAppoinment}`
}
