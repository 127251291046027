import React from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"

import {
  GATSBY_PATIENT_ENROLLMENT_KEY,
  GATSBY_PATIENT_ENROLLMENT_VALUE,
} from "gatsby-env-variables"

const PatientEnroll = () => {
  const handleSubmit = () => {
    sessionStorage.setItem(
      GATSBY_PATIENT_ENROLLMENT_KEY,
      GATSBY_PATIENT_ENROLLMENT_VALUE
    )
    navigate("/patient/enroll")
  }

  return (
    <Layout
      title="Patient Enrollment Form"
      subtitle="Please provide consent to the terms and conditions before proceeding."
      seoTitle="Patient Enrollment"
    >
      <Container isCentered>
        <ConsentAndAuthorization
          cta="Enroll as Patient"
          handleOnSubmit={handleSubmit}
        >
          <p>
            By submitting my enrollment, I acknowledge that I agree to the terms
            and conditions above.
          </p>
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default PatientEnroll
